<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统设置</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">培训大类归集</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="培训大类名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 8em">培训大类名称:</span>
              <el-input
                  v-model="collectionName"
                  type="text"
                  size="small"
                  clearable
                  placeholder="请输入培训大类名称"
              />
            </div>
<!--            <div title="机构名称" class="searchboxItem ci-full">-->
<!--              <span class="itemLabel">机构名称:</span>-->
<!--              -->
<!--              <el-select-->
<!--                  size="small"-->
<!--                  v-model="compId"-->
<!--                  remote-->
<!--                  :remote-method="getuserCompanyList"-->
<!--                  filterable-->
<!--                  clearable-->
<!--                  placeholder="请至少输入两个字搜索"-->
<!--                  style="width:100%"-->
<!--              >-->
<!--                <el-option-->
<!--                    v-for="item in userCompanyList"-->
<!--                    :key="item.compId"-->
<!--                    :label="item.compName"-->
<!--                    :value="item.compId"-->
<!--                ></el-option>-->
<!--              </el-select>-->
<!--            </div>-->
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="handleEdit('add')">新增</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="tableData"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  :index="indexMethod"
                  min-width="100"
              />
              <el-table-column
                  label="培训大类名称"
                  align="center"
                  show-overflow-tooltip
                  prop="collectionName"
                  min-width="150"
              />
              <el-table-column
                  label="培训类型"
                  align="center"
                  show-overflow-tooltip
                  prop="trainTypeName"
                  min-width="150"
              />
              <el-table-column label="操作" align="center" show-overflow-tooltip min-width="100" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                      type="text"
                      style="padding:0px 5px"
                      size="mini"
                      @click="handleEdit('edit',scope.row.collectionId,scope.row.enabled)"
                  >编辑</el-button>
                  <el-button
                      type="text"
                      style="padding:0px 5px"
                      size="mini"
                      @click="handleDelete(scope.row.collectionId)"
                  >删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
        <el-dialog
            :title="dialogTitle"
            top="4%"
            :visible.sync="dialogVisible"
            width="40%"
            :before-close="cancle"
        >
          <div>
            <el-form
                ref="ruleForm"
                label-width="8rem"
                class="form"
                :rules="rules"
                :model="ruleForm"
            >
              <el-form-item label="培训大类名称" prop="collectionName">
                <el-input v-model="ruleForm.collectionName" size="small"></el-input>
              </el-form-item>
              <el-form-item label="培训类型" prop="collectionName">
                <el-tree
                    style="margin-top: 7px"
                    :data="data"
                    show-checkbox
                    default-expand-all
                    node-key="id"
                    ref="tree"
                    highlight-current
                    :props="defaultProps">
                </el-tree>
<!--                <el-button @click="getCheckedKeys">通过 key 获取</el-button>-->
              </el-form-item>
            </el-form>
<!--            <el-table-->
<!--                ref="multipleTable"-->
<!--                :data="tableData2"-->
<!--                size="small"-->
<!--                tooltip-effect="dark"-->
<!--                style="width: 100%"-->
<!--                :header-cell-style="tableHeader"-->
<!--                stripe-->
<!--                row-key="id"-->
<!--                @selection-change="handleSelectionChange"-->
<!--            >-->
<!--              <el-table-column-->
<!--                  type="selection"-->
<!--                  width="55"-->
<!--                  :reserve-selection="true"-->
<!--              />-->
<!--              <el-table-column-->
<!--                  label="培训类型"-->
<!--                  align="center"-->
<!--                  show-overflow-tooltip-->
<!--                  prop="label"-->
<!--                  min-width="150"-->
<!--              />-->
<!--            </el-table>-->
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="cancle" class="bgc-bv">取 消</el-button>
            <el-button @click="sure()" class="bgc-bv">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
// import tree from "@/components/treePopup";
import moment from "moment";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "trainCategoryCollection",
  components: {
    Empty,
    PageNum,
    // tree
  },
  mixins: [List],
  data() {
    return {

      userCompanyList: [], //机构列表
      collectionName: "",
      compId: "",
      params: {},
      dialogTitle: '添加',
      dialogVisible: false,
      ruleForm: {
        collectionName: '',
      },
      collectionId: '',
      oldCheckList: [],
      rules: {
        collectionName: [
          {required: true, trigger: "blur", message: "培训大类名称不能为空"},
          {trigger: "blur", max: 20, message: "培训大类名称不超过20个字"},
        ],
      },
      data: [],
      defaultProps: {
        children: 'children',
        label: 'label',
        id: 'id'
      }
    };
  },
  created() {},
  mounted() {
    this.getTableHeight();
    this.getTrainingTypeList();
    // this.getareatree();
  },
  methods: {
    // getCheckedKeys() {
    //   console.log(this.$refs.tree.getCheckedKeys());
    //   console.log(this.$refs.tree.getCheckedNodes());
    // },
    // 获取培训类型一级类目
    getTrainingTypeList() {
        this.$post("/sys/category/train-type/tree", {})
            .then((res) => {
              if (res.status == 0) {
                  this.data = res.data || []
              }
            })
            .catch(() => {
              return;
            });
    },
    /* 机构名称 */
    // getuserCompanyList(query) {
    //   if (query.trim().length >= 2) {
    //     this.$post("/sys/company/queryCompanyList", { compName: query,compType:'30' })
    //         .then((res) => {
    //           if (res.status == 0) {
    //             this.userCompanyList = res.data || [];
    //           }
    //         })
    //         .catch(() => {
    //           return;
    //         });
    //   } else {
    //     this.userCompanyList = [];
    //   }
    // },
    /* tree */
    // childBack(params) {
    //   this.params = { ...params };
    // },
    // clearParams() {
    //   this.params = {};
    // },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
        // projectName: this.projectName || "",
      };
      if (this.collectionName) {
        params.collectionName = this.collectionName;
      }

      this.doFetch({
        url: "/category/trainTypeCollection/pageList",
        params,
        pageNum
      });
    },
    // //获取行政区划
    // getareatree() {
    //   this.$post("/sys/area/tree").then(ret => {
    //     this.areatreeList = ret.data;
    //   });
    // },
    // 添加编辑
    handleEdit(stu,collectionId) {
      if (stu == 'edit') {
        this.dialogTitle = '编辑'
        this.$post("/category/trainTypeCollection/getInfo", {collectionId})
            .then((ret) => {
              if (ret.status == 0) {
                this.collectionId = ret.data.collectionId
                this.ruleForm.collectionName = ret.data.collectionName
                let checkList = []
                ret.data.trainTypeList.forEach((item,index) => {
                  let childItem = {
                    collectionId: item.collectionId,
                    label: item.collectionName,
                    id: item.trainTypeId
                  }
                  checkList.push(childItem)
                })
                this.$refs.tree.setCheckedNodes(checkList);
                this.oldCheckList = checkList
              }
            })
            .catch((err) => {
              return;
            });
      }else{
        this.dialogTitle = '添加'
        this.collectionId = ''
      }
      this.dialogVisible = true
    },
    // handleSelectionChange(val) {
    //   this.multipleSelection = val;
    // },
    sure() {
      let _this = this
      if (!this.ruleForm.collectionName) {
        this.$message({
          message: "请输入培训大类名称",
          type: "warning",
        });
        return false;
      }
      const parmar = {
        collectionName: this.ruleForm.collectionName,
        trainTypeList: []
      };
      let url = ''
      if(this.collectionId){
        parmar.collectionId = this.collectionId
        url = '/category/trainTypeCollection/modify'
      }else{
        url = '/category/trainTypeCollection/insert'
      }
      if(this.$refs.tree.getCheckedNodes().length == 0){
        this.$message({
          message: "请至少选择一项培训类型",
          type: "warning",
        });
        return false;
      }
      if(this.$refs.tree.getCheckedNodes().length > 0){
        let trainTypeList = []
        this.$refs.tree.getCheckedNodes().forEach((item,index) => {
          let childItem = {
            collectionName: item.label,
            trainTypeId: item.id
          }
          _this.oldCheckList.forEach((itemInner,indexInner)=>{
            if(item.id == itemInner.id){
              childItem.collectionId = itemInner.collectionId
            }
          })
          trainTypeList.push(childItem)
        })
        parmar.trainTypeList = trainTypeList
      }

      console.log(parmar)
      this.$post(url, parmar)
          .then((ret) => {
            if (ret.status == 0) {
              this.$message({
                type: "success",
                message: "成功",
              });
              _this.getData(-1)
              _this.cancle()
            }
          })
          .catch((err) => {
            return;
          });
    },
    cancle() {
      this.ruleForm.collectionName = "";
      this.$refs.tree.setCheckedNodes([]);
      this.$refs.ruleForm.clearValidate();
      this.oldCheckList = []
      this.dialogVisible = false;
    },
    // 删除
    handleDelete(collectionId){

      this.$confirm("确定删除该培训大类吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.$post(
                "/category/trainTypeCollection/delete",
                { collectionId },
            ).then((res) => {
              if (res.status == "0") {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.getData(-1)
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    }

  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        console.log(val.query)
        if (val.query.refresh == 'true') {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true
    }
  }
};
</script>
<style lang="less" scope>
.searchBox {
  padding: 0 !important;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>
